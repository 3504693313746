<template>
    <div
        class="d-flex flex-column slide"
        ref="teaser"
        data-cy="product-teaser"
        :data-cy-available="isAvailable"
    >
        <div class="slide__image">
            <NuxtLink
                :to="slide?.link?.to || slide?.html_url || '#'"
                @click="handleSelectItem"
            >
                <ProductImage
                    :image="
                        slide?.image
                            ? slide.image.image
                            : slide.images
                              ? slide.images[0]
                              : ''
                    "
                    sizes="sm:320px md:460px lg:620px"
                    :new="slide.image?.new"
                    :offer="slide.image?.offer"
                    :classic="slide.image?.classic"
                    :special="slide.image?.special"
                    :organism="slide.image?.organism"
                    :sold-out="!isAvailable"
                    :productTitle="slide.title"
                />
            </NuxtLink>
        </div>
        <div class="d-flex align-items-start flex-column slide__content">
            <div class="slide__product">
                <div
                    class="equal-small-2 equal-small-2--bold-uppercase slide__type"
                >
                    {{ slide.type }}
                </div>
                <div class="slide__product-info">
                    <NuxtLink
                        :to="slide?.link?.to || '#'"
                        class="slide__title-link"
                        @click="handleSelectItem"
                    >
                        <h3
                            class="scale-1 scale-1--bold-uppercase slide__title mb-0"
                        >
                            {{ slide.title }}
                            <span
                                class="equal-small-2 equal-small-2--bold-uppercase slide__info"
                                v-if="slide.info"
                            >
                                {{ slide.info }}
                            </span>
                        </h3>
                    </NuxtLink>
                </div>
            </div>
            <div class="d-flex flex-row w-100 mt-auto">
                <div class="align-self-end d-flex flex-column flex-grow-1">
                    <div
                        class="equal-small-1 slide__price-invalid"
                        v-if="price?.action"
                    >
                        {{ price?.currency }}
                        {{ price?.original_incl_tax
                        }}<span v-if="slide.structure !== 'standalone'">
                            / 100g</span
                        >
                    </div>
                    <div
                        class="equal-base equal-base--tighter-bold slide__price"
                        :class="{ 'slide__price--offer': price?.action }"
                    >
                        {{ price?.currency }}
                        {{
                            price?.action
                                ? price?.incl_tax
                                : price?.original_incl_tax
                        }}
                        <span v-if="slide.structure !== 'standalone'">
                            / 100g</span
                        >
                    </div>
                </div>
                <div
                    v-if="isAvailable"
                    class="align-self-end flex-shrink-0"
                    data-cy="product-picker-button"
                >
                    <ProductPicker
                        :product="product"
                        :availabilities="availabilities"
                        placement="bottom"
                        :backdrop="false"
                        :size40="true"
                        parent="teaser"
                        :parent-title="parentTitle"
                        :parent-type="parentType"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useElementVisibility } from '~/composables/useElementVisibility';
import { useTrackSelectItem } from '~/composables/gtm/useEcomTracking';
import { useAvailability } from '~/composables/useAvailability';
import { useSeoStore } from '~/stores/seo';

const props = defineProps({
    slide: {
        type: Object,
        required: true,
    },
    index: {
        type: Number,
        required: false,
    },
    parentType: {
        type: String,
        required: false,
    },
    parentTitle: {
        type: String,
        required: false,
    },
});

const teaser = ref(null);
const targetIsVisible = useElementVisibility(teaser);
const { loaded, loading, availabilities, success, isAvailable, parentPrice } =
    useAvailability(targetIsVisible, props.slide);

const product = computed(() => {
    return {
        ...props.slide,
    };
});

const price = computed(() => {
    if (parentPrice.value) {
        return parentPrice.value;
    }
    if (props.slide?.price?.value) {
        return props.slide.price;
    }
    if (availabilities.value.length > 0) {
        return availabilities.value[0];
    }
});

const seoStore = useSeoStore();

function handleSelectItem() {
    useTrackSelectItem(
        product.value,
        props.parentType,
        props.parentTitle,
        props.index,
        price.value,
    );
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slide {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: $color-dark-03;
    text-align: left;
    transition: 0.5s ease;
    .product-image {
        overflow: hidden;
        :deep(.product-image__image) {
            transition: 0.5s ease;
        }
    }
    &:hover,
    &:focus,
    &:active {
        background-color: $color-dark-02;
        transition: 0.5s ease;
        .product-image:deep(.product-image__image) {
            transform: scale(1.05);
            transition: 0.5s ease;
        }
    }
    &___image {
        max-height: 213px;
    }

    &__content {
        height: 100%;
        padding: 25px;
    }

    &__product {
        width: 100%;
        margin-bottom: 20px;
    }

    &__type {
        color: $color-disabled;
        @include media-breakpoint-down(640) {
            margin-bottom: 1px;
        }
    }

    &__title {
        margin-right: 10px;
    }

    &__info {
        color: $color-disabled;
        align-self: flex-end;
    }

    &__price {
        &--offer {
            color: #c62424;
        }
    }

    &__price-invalid {
        color: #999999;
        text-decoration: line-through;
    }

    &__button {
        margin-left: 10px;
    }
}
</style>
